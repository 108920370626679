import axios from '../../axios-auth.js'
import globalAxios from 'axios'

const state = {
    booking: {
        inquiryId: null,
        typeId: null,
        boat: null,
        customer: null,
        privateNote: null,
        pax: null,
        skipperEur: null,
        range: null,
        pickupTime: null,
        price: null,
        discount: null,
        discountEur: null,
        totalPrice: null,
        expiry: null,
        tour: null,
        transfer: null
    },
    sendMail: true
}

const mutations = {
    SET_BOOKING(state, booking) {
        //console.log(state.booking, booking);
        // merge data with previous state
        state.booking = Object.assign({}, state.booking, booking)
    },
    CLEAR_BOOKING(state) {
        state.booking = {}
    },
    SET_SENDMAIL(state, value) {
        state.sendMail = value
        localStorage.setItem('sendMail', value)
    },
    INIT_STORE(state) {
        if (localStorage.getItem('sendMail')) {
            // Replace the state object with the stored item
            state.sendMail = ('true' == localStorage.getItem('sendMail'))
        }
    }
}

const actions = {
    fetchBooking({ commit, state }) {
        if (!state.idToken)
            return
        axios.get('users')
            .then(res => {
                const data = res.data
                const users = []
                for (let key in data) {
                    const user = data[key]
                    user.id = key
                    users.push(user)
                }
                commit('storeUser', users[0])
            })
            .catch(error => console.log(error))
    }
}

const getters = {
    booking(state) {
        return state.booking
    },
    sendMail(state) {
        return state.sendMail
    }
}

export default {
    namespace: true,
    state,
    mutations,
    actions,
    getters
}