import Vue from 'vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import axios from '../../axios-auth.js'
import starterRouter from '../../routes/starterRouter.js';

Vue.use(VueLodash, { lodash: lodash })

const state = {
    boatTypes: [],
    boats: [],
    inquiries: [],
    offers: [],
    types: [
        {
            label: 'Rental',
            value: 1
        },
        /*{
            label: 'Group excursion',
            value: 2
        },*/
        {
            label: 'Tour',
            value: 3
        },
        {
            label: 'Transfer',
            value: 4
        }
    ],
    paymentOptions: [
        {
            label: 'Cash',
            value: 20
        },
        {
            label: 'PayPal',
            value: 1,
            hasInvoice: true
        },
        {
            label: 'Revolut',
            value: 2
        },
        {
            label: 'Stripe',
            value: 3
        },
        {
            label: 'myPOS',
            value: 4
        },
        {
            label: 'Click&Boat',
            value: 5
        },
        {
            label: 'Zizoo',
            value: 6
        },
        {
            label: 'SamBoat',
            value: 7
        },
        {
            label: 'GetMyBoat',
            value: 8
        },
        {
            label: 'Viator',
            value: 9
        },
        {
            label: 'CheckYeti',
            value: 10
        },
        {
            label: 'BednBlue',
            value: 11
        },
        {
            label: 'Wire transfer',
            value: 100
        },
    ]
}

const mutations = {
    setBoatTypes(state, types) {
        Vue.set(state, 'boatTypes', types)
    },
    setBoats(state, boats) {
        Vue.set(state, 'boats', boats)
    },
    setInquiriesBoats(state, inquiries) {
        //console.log(inquiries.inquiries)
        state.inquiries = inquiries
    },
    removeInquiry(state, inquiry) {
        const index = Vue._.findIndex(state.inquiries, ['id', inquiry.id])
        if (index >= 0) {
            Vue.delete(state.inquiries, index)
                //state.inquiries.splice(index, 1);
        }
    },
    setOffers(state, offers) {
        //console.log('setOffers', offers)
        state.offers = offers
    },
    setConfirmedBookings(state, bookings) {
        //console.log('setOffers', offers)
        state.confirmedBookings = bookings
    },
    confirmBooking(state, booking) {
        //console.log(state.bookings, booking.booking)
        const index = state.bookings.findIndex(e => e.id === booking.booking.id)
            //Vue.set(state.bookings, index, booking.booking)
        Vue.delete(state.bookings, index)
    },
    modifyBooking(state, booking) {
        const index = state.bookings.findIndex(e => e.id === booking.booking.id)
        console.log(index)
        Vue.set(state.bookings, index, booking)
    },
}

const actions = {
    fetchBoatTypes({ commit, rootState }) {
        if (!rootState.auth.idToken)
            return

        axios.get('boat_types')
            .then(res => {
                const types = res.data.types
                //localStorage.setItem('types', JSON.stringify(types))
                commit('setBoatTypes', types)
            })
            .catch(error => console.log(error))
    },
    fetchBoats({ commit }) {
        //const boatsFromStorage = JSON.parse(localStorage.getItem('boats'))
        const boatsFromStorage = null
        if (boatsFromStorage === null) {
            axios.get('boats')
                .then(res => {
                    const boats = res.data.boats
                    //localStorage.setItem('boats', JSON.stringify(boats))
                    commit('setBoats', boats)
                })
                .catch(error => console.log(error))
        } else {
            commit('setBoats', {
                boats: boatsFromStorage
            })
        }

    },
    fetchInquiriesBoats({ commit, rootState }, payload) {
        //console.log('status', status)        
        return axios.get(`inquiries`) //?start=${payload.start.split('T')[0]}&end=${payload.start.split('T')[0]}
            .then(res => {
                const inquiries = res.data.inquiries
                commit('setInquiriesBoats', inquiries)
                return inquiries
            })
            .catch(error => console.log(error))
    },
    fetchInquiriesTransfers({ commit, rootState }, payload) {
        //console.log('status', status)        
        return axios.get(`inquiries_transfers`) //?start=${payload.start.split('T')[0]}&end=${payload.start.split('T')[0]}
            .then(res => {
                const inquiries = res.data.inquiries
                //commit('setInquiriesBoats', inquiries)
                return inquiries
            })
            .catch(error => console.log(error))
    },
    fetchOffers({ commit }, payload) {
        let params = '';
        if (payload) {
            if ('start' in payload) {
                params += payload.start + '/'
            }
            if ('end' in payload) {
                params += payload.end + '/'
            }
        }
        return axios.get('offers/' + params)
            .then(res => {
                const offers = res.data.offers
                commit('setOffers', offers)
                return offers
            })
            .catch(error => console.log(error))
    },
    fetchConfirmedBookings({ commit }, payload) {
        let params = '';
        if (payload) {
            if ('start' in payload) {
                params += payload.start + '/'
            }
            if ('end' in payload) {
                params += payload.end + '/'
            }
        }
        return axios.get('bookings/' + params)
            .then(res => {
                const confirmed = res.data.bookings
                commit('setConfirmedBookings', confirmed)
                return confirmed
            })
            .catch(error => console.log(error))
    },
    sendOffer: ({ commit }, offer) => {
        const data = {
            offer
        }
        return axios.post('send_offer', data)
            .then(res => {
                if (res.status == 200) {
                    //console.log(res)
                    return {
                        success: true
                    }
                }
                return {
                    success: false,
                    error: 'Unknown'
                }
            }).
        catch(error => {
            return {
                success: false,
                error: error
            }
        })
    },
    editBooking: ({ commit }, payload) => {
        const data = {
            data: payload
        }
        return axios.post('edit_booking', data)
            .then(res => {
                if (res.status == 200) {
                    //commit('modifyBooking', booking)
                    return true
                }
                return false
            })
            .catch(error => {
                console.log(error)
                return false
            })
    },
    confirmAvailability: ({ commit, rootState }, booking) => {
        if (!rootState.auth.idToken)
            return
        const data = {
            bookingId: booking.id
        }
        return axios.post('confirm_availability', data, {
                headers: { "Authorization": rootState.auth.idToken }
            })
            .then(res => {
                if (res.status == 200) {
                    commit('modifyBooking', {
                        booking
                    })
                    return true
                }
                return false
            })
            .catch(error => {
                console.log(error)
                return false
            })
    },
    rejectInquiry: ({ commit, rootState }, inquiry) => {
        //console.log(inquiry)
        const data = {
            inquiryId: inquiry.id
        }
        return axios.post('reject_inquiry', data)
            .then(res => {
                if (res.status == 200) {
                    //console.log(res)
                    commit('removeInquiry', inquiry)
                    return true
                }
                return false
            })
            .catch(error => {
                console.log(error)
                return false
            })
    },
    setBookins: ({ commit }) => {
        commit('SET_BOOKINKS')
    }
}

const getters = {
    boatTypes: state => {
        return state.boatTypes
    },
    boats: state => {
        return state.boats
    },
    boat: (state) => (id) => {
        return Vue._.find(state.boats, ['id', id])
    },
    boatsOptions: state => {
        state.boats.map(boats => {
            return
        })
        return state.boats
    },
    bookingStatuses: state => {
        return state.bookingStatuses
    },
    types: state => {
        return state.types;
    },
    paymentOptions: state => {
        return state.paymentOptions
    },
    inquiries: (state, getters) => {
        if (state.inquiries) {
            return state.inquiries.map(inquiry => {
                const boatType = Vue._.find(getters.boatTypes, ['id', inquiry.boat_type_id])
                return {
                    ...inquiry,
                    boatType: boatType
                }
            })
        }
    },
    offers: (state, getters) => {
        if (state.offers) {
            return state.offers.map(offer => {
                const boatType = Vue._.find(getters.boatTypes, ['id', offer.boat.type])
                return {
                    ...offer,
                    boatType: boatType
                }
            })
        }
    },
    confirmedBookings: (state, getters) => {
        if (state.confirmedBookings) {
            return state.confirmedBookings.map(booking => {
                const boatType = Vue._.find(getters.boatTypes, ['id', booking.boat.type])
                return {
                    ...booking,
                    boatType: boatType
                }
            })
        }
    },
    events: (state, getters) => {
        if (state.inquiries) {
            return state.inquiries.map(inquiry => {
                const boatType = Vue._.find(getters.boatTypes, ['id', inquiry.boat_type_id])
                    //console.log(getters.boatTypes, inquiry.boat_type_id)
                    //console.log(boatType)
                    //console.log(inquiry)
                return {
                    start: inquiry.start,
                    end: inquiry.end,
                    title: boatType.name + ' - ' + inquiry.customer.name,
                    id: inquiry.id,
                    className: new Date(inquiry.end).getTime() < new Date().getTime() ? 'bg-light' : 'bg-primary',
                    quantity: inquiry.quantity,
                    boatType: boatType,
                    customer: inquiry.customer,
                    status: inquiry.status,
                    totalPrice: inquiry.total_price
                }
                // const bookingStatus = Vue._.find(getters.bookingStatuses, ['id', parseInt(inquiry.status)])

                // className: new Date(inquiry.end).getTime() < new Date().getTime() ? 'bg-light' : bookingStatus.className,

            })
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}