<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Stats</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <!--<div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>-->
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="completedStats.current && bookedStats.current" 
                      :title="completedStats.current.noTotal + ' completed till ' + moment().format('DD.MM')"
                      type="gradient-red"
                      :sub-title="'€' + completedStats.current.eurTotal"
                      icon="ni ni-money-coins">

            <template slot="footer">
              <!--<div class="d-flex align-items-center">
                <span class="completion mr-1">{{parseFloat((completedStats.current.eurTotal/bookedStats.current.eurTotal)*100).toFixed(0)}}%</span>
                <div>
                  <base-progress :type="(completedStats.current.eurTotal/bookedStats.current.eurTotal)*100 >= 0 ? 'success' : 'danger'" :value="completedStats.current.eurTotal/bookedStats.current.eurTotal"/>
                </div>
              </div>-->
              <span class="text-nowrap mr-2" :class="{'text-success': completedStats.change.eurTotal >= 0, 'text-danger': completedStats.change.eurTotal < 0}"><i class="fa" :class="{'fa-arrow-up': completedStats.change.eurTotal >= 0, 'fa-arrow-down': completedStats.change.eurTotal < 0}"></i>{{Math.round(completedStats.change.eurTotal)}}%</span>
              <span>earn (<strong>€{{completedStats.compare.eurTotal}}</strong>), </span>
              <span class="text-nowrap mr-2" :class="{'text-success': completedStats.change.noTotal >= 0, 'text-danger': completedStats.change.noTotal < 0}"><i class="fa" :class="{'fa-arrow-up': completedStats.change.noTotal >= 0, 'fa-arrow-down': completedStats.change.noTotal < 0}"></i>{{Math.round(completedStats.change.noTotal)}}%</span>
              <span># (<strong>{{completedStats.compare.noTotal}}</strong>)</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="bookedStats.current" 
                      :title="bookedStats.current.noTotal + ' booked for ' + bookedStats.current.year "
                      type="gradient-red"
                      :sub-title="'€' + bookedStats.current.eurTotal"
                      icon="ni ni-money-coins">

            <template slot="footer">
              <!--<div class="d-flex align-items-center">
                <span class="completion mr-1">{{parseFloat((bookedStats.current.eurTotal/bookedStats.current.eurTotal)*100).toFixed(0)}}%</span>
                <div>
                  <base-progress :type="(bookedStats.current.eurTotal/bookedStats.current.eurTotal)*100 >= 0 ? 'success' : 'danger'" :value="bookedStats.current.eurTotal/bookedStats.current.eurTotal"/>
                </div>
              </div>-->
              <span class="text-nowrap mr-2" :class="{'text-success': bookedStats.change.eurTotal >= 0, 'text-danger': bookedStats.change.eurTotal < 0}"><i class="fa" :class="{'fa-arrow-up': bookedStats.change.eurTotal >= 0, 'fa-arrow-down': bookedStats.change.eurTotal < 0}"></i>{{Math.round(bookedStats.change.eurTotal)}}%</span>
              <span>earn (<strong>€{{bookedStats.compare.eurTotal}}</strong>), </span>
              <span class="text-nowrap mr-2" :class="{'text-success': bookedStats.change.noTotal >= 0, 'text-danger': bookedStats.change.noTotal < 0}"><i class="fa" :class="{'fa-arrow-up': bookedStats.change.noTotal >= 0, 'fa-arrow-down': bookedStats.change.noTotal < 0}"></i>{{Math.round(bookedStats.change.noTotal)}}%</span>
              <span># (<strong>{{bookedStats.compare.noTotal}}</strong>)</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="completedStats.current" 
                      :title="completedStats.current.noRentals + ' rentals completed, ' + completedStats.current.noRentalsWithSkipper + ' with skipper'"
                      type="gradient-orange"
                      :sub-title="'€' + completedStats.current.eurRentals"
                      icon="ni ni-chart-bar-32">
            <template slot="footer">
              <span class="mr-2 text-nowrap" :class="{'text-success': completedStats.change.eurRentals >= 0, 'text-danger': completedStats.change.eurRentals < 0}"><i class="fa" :class="{'fa-arrow-up': completedStats.change.eurRentals >= 0, 'fa-arrow-down': completedStats.change.eurRentals < 0}"></i> {{Math.round(completedStats.change.eurRentals)}}%</span>
              <span>earn (<strong>€{{completedStats.compare.eurRentals}}</strong>), </span>
              <span class="mr-2 text-nowrap" :class="{'text-success': completedStats.change.noRentals >= 0, 'text-danger': completedStats.change.noRentals < 0}"><i class="fa" :class="{'fa-arrow-up': completedStats.change.noRentals >= 0, 'fa-arrow-down': completedStats.change.noRentals < 0}"></i> {{Math.round(completedStats.change.noRentals)}}%</span>
              <span># (<strong>{{completedStats.compare.noRentals}}</strong>)</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="bookedStats.current" 
                      :title="bookedStats.current.noRentals + ' rentals booked, ' + bookedStats.current.noRentalsWithSkipper + ' w/skipper'"
                      type="gradient-orange"
                      :sub-title="'€' + bookedStats.current.eurRentals"
                      icon="ni ni-chart-bar-32">
            <template slot="footer">
              <span class="mr-2 text-nowrap" :class="{'text-success': bookedStats.change.eurRentals >= 0, 'text-danger': bookedStats.change.eurRentals < 0}"><i class="fa" :class="{'fa-arrow-up': bookedStats.change.eurRentals >= 0, 'fa-arrow-down': bookedStats.change.eurRentals < 0}"></i> {{Math.round(bookedStats.change.eurRentals)}}%</span>
              <span>earn (<strong>€{{bookedStats.compare.eurRentals}}</strong>), </span>
              <span class="mr-2 text-nowrap" :class="{'text-success': bookedStats.change.noRentals >= 0, 'text-danger': bookedStats.change.noRentals < 0}"><i class="fa" :class="{'fa-arrow-up': bookedStats.change.noRentals >= 0, 'fa-arrow-down': bookedStats.change.noRentals < 0}"></i> {{Math.round(bookedStats.change.noRentals)}}%</span>
              <span># (<strong>{{bookedStats.compare.noRentals}}</strong>)</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="completedStats.current"
                      :title="completedStats.current.noTours + ' tours completed'"
                      type="gradient-green"
                      :sub-title="'€' + completedStats.current.eurTours"
                      icon="ni ni-cart">

            <template slot="footer">
              <span class="mr-2 text-nowrap" :class="{'text-success': completedStats.change.eurTours >= 0, 'text-danger': completedStats.change.eurTours < 0}"><i class="fa" :class="{'fa-arrow-up': completedStats.change.eurTours >= 0, 'fa-arrow-down': completedStats.change.eurTours < 0}"></i> {{Math.round(completedStats.change.eurTours)}}%</span>
              <span>earn (<strong>€{{completedStats.compare.eurTours}}</strong>), </span>
              <span class="mr-2 text-nowrap" :class="{'text-success': completedStats.change.noTours >= 0, 'text-danger': completedStats.change.noTours < 0}"><i class="fa" :class="{'fa-arrow-up': completedStats.change.noTours >= 0, 'fa-arrow-down': completedStats.change.noTours < 0}"></i> {{Math.round(completedStats.change.noTours)}}%</span>
              <span># (<strong>{{completedStats.compare.noTours}}</strong>)</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="bookedStats.current"
                      :title="bookedStats.current.noTours + ' tours booked'"
                      type="gradient-green"
                      :sub-title="'€' + bookedStats.current.eurTours"
                      icon="ni ni-cart">

            <template slot="footer">
              <span class="mr-2 text-nowrap" :class="{'text-success': bookedStats.change.eurTours >= 0, 'text-danger': bookedStats.change.eurTours < 0}"><i class="fa" :class="{'fa-arrow-up': bookedStats.change.eurTours >= 0, 'fa-arrow-down': bookedStats.change.eurTours < 0}"></i> {{Math.round(bookedStats.change.eurTours)}}%</span>
              <span>earn (<strong>€{{bookedStats.compare.eurTours}}</strong>), </span>
              <span class="mr-2 text-nowrap" :class="{'text-success': bookedStats.change.noTours >= 0, 'text-danger': bookedStats.change.noTours < 0}"><i class="fa" :class="{'fa-arrow-up': bookedStats.change.noTours >= 0, 'fa-arrow-down': bookedStats.change.noTours < 0}"></i> {{Math.round(bookedStats.change.noTours)}}%</span>
              <span># (<strong>{{bookedStats.compare.noTours}}</strong>)</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card  v-if="completedStats.current"
                      :title="completedStats.current.noTransfers + ' transfers completed'"
                      type="gradient-info"
                      :sub-title="'€' + completedStats.current.eurTransfers"
                      icon="ni ni-shop">

            <template slot="footer">
              <span class="mr-2 text-nowrap" :class="{'text-success': completedStats.change.eurTransfers >= 0, 'text-danger': completedStats.change.eurTransfers < 0}"><i class="fa" :class="{'fa-arrow-up': completedStats.change.eurTransfers >= 0, 'fa-arrow-down': completedStats.change.eurTransfers < 0}"></i> {{Math.round(completedStats.change.eurTransfers)}}%</span>
              <span>earn (<strong>€{{completedStats.compare.eurTransfers}}</strong>), </span>
              <span class="mr-2 text-nowrap" :class="{'text-success': completedStats.change.noTransfers >= 0, 'text-danger': completedStats.change.noTransfers < 0}"><i class="fa" :class="{'fa-arrow-up': completedStats.change.noTransfers >= 0, 'fa-arrow-down': completedStats.change.noTransfers < 0}"></i> {{Math.round(completedStats.change.noTransfers)}}%</span>
              <span># (<strong>{{completedStats.compare.noTransfers}}</strong>)</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card  v-if="bookedStats.current"
                      :title="bookedStats.current.noTransfers + ' transfers booked'"
                      type="gradient-info"
                      :sub-title="'€' + bookedStats.current.eurTransfers"
                      icon="ni ni-shop">

            <template slot="footer">
              <span class="mr-2 text-nowrap" :class="{'text-success': bookedStats.change.eurTransfers >= 0, 'text-danger': bookedStats.change.eurTransfers < 0}"><i class="fa" :class="{'fa-arrow-up': bookedStats.change.eurTransfers >= 0, 'fa-arrow-down': bookedStats.change.eurTransfers < 0}"></i> {{Math.round(bookedStats.change.eurTransfers)}}%</span>
              <span>earn (<strong>€{{bookedStats.compare.eurTransfers}}</strong>), </span>
              <span class="mr-2 text-nowrap" :class="{'text-success': bookedStats.change.noTransfers >= 0, 'text-danger': bookedStats.change.noTransfers < 0}"><i class="fa" :class="{'fa-arrow-up': bookedStats.change.noTransfers >= 0, 'fa-arrow-down': bookedStats.change.noTransfers < 0}"></i> {{Math.round(bookedStats.change.noTransfers)}}%</span>
              <span># (<strong>{{bookedStats.compare.noTransfers}}</strong>)</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="mt-5 mb-xl-0 col-xl-12">
          <div class="card">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">Stats per boat</h6>
                  <h5 class="h3 mb-0">Rentals & earnings</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <el-table v-if="showTable" class="table-responsive"
                  header-row-class-name="thead-light"
                  :data="boats">
            <el-table-column label="Boat"
                             min-width="150px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <a href="#" class="avatar rounded-circle mr-3">
                            <img alt="Image placeholder" :src="'https://www.hvarboats.com/img/boat/small/' + row.photo">
                        </a>
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.name}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="done"
                             prop="statsCompleted.current.eurTotal"
                             min-width="120px"
                             sortable>
                             <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <span>€{{parseFloat(row.statsCompleted.current.eurTotal).toFixed(2) }}</span>
                        <div>#{{parseInt(row.statsCompleted.current.noTotal)}}</div>
                    </badge>
                </template>
            </el-table-column>
            <el-table-column label="booked"
                             prop="statsBooked.current.eurTotal"
                             min-width="120px"
                             sortable>
                             <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <span>€{{parseFloat(row.statsBooked.current.eurTotal).toFixed(2) }}</span>
                        <div>#{{parseInt(row.statsBooked.current.noTotal)}}</div>
                    </badge>
                </template>
            </el-table-column>
            <el-table-column label="progress"
                prop="statsCompleted.change.statsCompleted"
                min-width="170px"
                sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center" v-if="row.statsCompleted.current.noTotal > 0">
                        <span class="completion mr-1">{{Math.round((row.statsCompleted.current.noTotal/row.statsBooked.current.noTotal)*100)}}%</span>
                        <div>
                            <base-progress :type="Math.round((row.statsCompleted.current.noTotal/row.statsBooked.current.noTotal)*100) >= 50 ? 'success' : 'danger'" :value="Math.round((row.statsCompleted.current.noTotal/row.statsBooked.current.noTotal)*100)"/>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="chng"
                             prop="statsCompleted.change.statsCompleted"
                             min-width="130px"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <i :class="`bg-${row.statsBooked.change.eurTotal >= 0 ? 'success' : 'danger'}`"></i>
                        <span class="status">{{Math.round(row.statsBooked.change.eurTotal)}}%</span>
                    </badge>
                </template>
            </el-table-column>
            <!--<el-table-column label="Progress"
                             prop="statsCompleted.change.statsCompleted"
                             min-width="100px"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <span class="completion mr-1">{{parseFloat(row.statsCompleted.change.statsCompleted).toFixed(0)}}%</span>
                        <div>
                            <base-progress :type="row.statsCompleted.change.statsCompleted >= 0 ? 'success' : 'danger'" :value="row.statsCompleted.change.earnings"/>
                        </div>
                    </div>
                </template>
            </el-table-column>-->
        </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import { mapGetters } from 'vuex'
  import axios from '../../axios-auth.js'

  Vue.use(require('vue-moment'));

  export default {
    name: 'starter-page',
    components: {
      StatsCard,
      RouteBreadCrumb,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        completedStats: [],
        bookedStats: [],
        showTable: false
      };
    },
     computed: {
      ...mapGetters([
        'boats'
      ]),
      currentDate: {
        get() {
          return this.moment().format("YYYY-MM-DD")
        }
      }
    },
    async created () {
      this.$store.dispatch('fetchBoats');
      this.completedStats = await this.getBookingStats(null, this.currentDate)
      //console.log(this.completedStats)
      this.bookedStats = await this.getBookingStats(null, null);
      for (let boat of this.boats) {
        boat.statsCompleted = await this.getBookingStats(boat.id, this.currentDate)
        boat.statsBooked = await this.getBookingStats(boat.id, null)
        //console.log(boat);
      }
      this.showTable = true;
    },
    methods: {
      async getBookingStats(boatInstanceId, cutoffDate) {
        //console.log(start, end)
              this.loading = true
              return axios.get('booking_stats/' + boatInstanceId + "/" + cutoffDate)
              .then(res => {
                this.loading = false
                return res.data.stats || []
              })
      }, 
    }
  };
</script>
<style>
  .starter-page {
    min-height: calc(100vh - 380px);
  }
</style>
